import * as React from 'react';
import { PageTitle } from '../../components/title/title';
import './kubernetes.scss';
import AmazeeIoMetadata from '../../components/metadata';
import ProgressiveImage from 'react-progressive-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import AmazeePage from '../../components/layout';

const SpacePagePanel: React.FC<{
  slug?: string;
  header: string;
  k8Text: string;
  lagoonText: string;
  planetImageUrl: string;
  planetImageHeight?: number;
  planetImageWidth?: number;
  extraPlanetStyle?: object;
  imageTopUrl: string;
  imageTopAlt: string;
  imageBottomUrl: string;
  imageBottomAlt: string;
  isAtTop?: boolean;
  isAtBottom?: boolean;
}> = ({
  children, slug, header, k8Text, lagoonText, planetImageUrl, imageBottomUrl, imageTopUrl, planetImageHeight = 128, planetImageWidth = 128, extraPlanetStyle = {}, imageTopAlt, imageBottomAlt, isAtTop = false, isAtBottom = false,
}) => {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (document) {
            const ratio = window.innerWidth > 992 ? 0.4 : 0.2;
            const pageIsNotScrolled = document.querySelector('html')?.scrollTop < 100;
            const pageIsScrolledPastLastElement = document.querySelector('html')?.scrollTop > entry.target.offsetTop;
            setVisible((entry.isIntersecting && entry.intersectionRatio > ratio) || (isAtTop && pageIsNotScrolled) || (pageIsScrolledPastLastElement && isAtBottom));
          }
        });
      }, {
        threshold: [0, 0.25, 0.5, 0.75, 1],
        trackVisibility: false,
        delay: 100,
      });
      observer.observe(domRef.current);
      return () => observer.unobserve(domRef.current);
    }, []);

    const planet = <div className="planet-wrapper"><div className="planet" style={{ backgroundImage: `url('${planetImageUrl}')`, backgroundSize: 'contain', ...extraPlanetStyle }} /></div>;
    const headerWords = header.split(' ');
    const renderedHeader = (
      <div className="header-wrapper">
        {planet}
        <div className="invisible" style={{ height: planetImageHeight }} />
        <div className="header">
          <b>{headerWords.slice(0, 1)}</b>
          <span>{headerWords.slice(1).join(' ')}</span>
        </div>
      </div>
    );

    return (
      <div className={`row justify-end panel-wrapper fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
        <div className={`col-12 col-md-10 panel panel--${slug}`}>
          {children}
          <div className="bg" />
          <div className="panel__content">

            {renderedHeader}

            <div className="panel__body mt-4">
              <div className="row mb-8 items-center">
                <div className="col-12 col-md-8 bullet">
                  <div className="inner flex items-center">
                    <img src="/space/2x/logo-k8.png" alt="K8 Logo" className="logo" />
                    {k8Text}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <img src={imageTopUrl} alt={imageTopAlt} />
                </div>
              </div>

              <div className="divider" />

              <div className="row mt-8 items-center">
                <div className="col-12 col-md-8 bullet">
                  <div className="inner flex items-center">
                    <img src="/space/2x/logo-lagoon.png" alt="Lagoon Logo" className="logo" />
                    {lagoonText}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <img src={imageBottomUrl} alt={imageBottomAlt} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const SpacePage = ({ location }: { location: Location }) => {
  const metaData = {
    location,
    title: 'Blast off with Kubernetes + Lagoon',
    page_meta_description: 'Launch 4x faster and save an average of 90% on hosting platform and infrastructure costs. Using managed hosting services based on Lagoon and native Kubernetes empowers developers, saves time and infrastructure costs, and allows you to scale effortlessly.',
    page_meta_thumbnail: {
      url: 'http://amazee.io/space/Blast-off-Opt-1.jpg',
      alt: 'Space image'
    }
  };

  let timer: any = null;
  let originalUrl: string;

  if (typeof window !== 'undefined' && location.href) {
    originalUrl = location.href;
    timer = setInterval(() => {
      if (originalUrl !== window.location.href) {
        document.querySelector('.st-sticky-share-buttons')?.remove();
        window.clearInterval(timer);
      }
    }, 3000);
  }

  return (
    <AmazeePage location={location}>
      <Helmet>
        <script async src="https://platform-api.sharethis.com/js/sharethis.js#property=5ea9a66fa49f7c001233c886&product=sticky-share-buttons"></script>
      </Helmet>
      <ParallaxProvider>
        <ProgressiveImage
          delay={3000}
          src="/space/all-assets-full-bg.jpg"
          placeholder="/space/all-assets-full-bg-tiny.jpg">
          {(blurBg) => {
            return (
              <div>
                <PageTitle title=' Blast off with Kubernetes + Lagoon' />
                <AmazeeIoMetadata {...metaData} />
                <div id="kubernetees-page">

                  <div className="blur-bg">
                    <div className='inner' style={{ backgroundImage: `url('${blurBg}')` }} />
                  </div>

                  {/* <Parallax y={[-40, 40]} className="stars"></Parallax> */}
                  <div className="stars"></div>

                  <div className="earth-smoke" />
                  <div className="earth" />

                  <div className="astronaut">
                    <div className="container relative">
                      <img src="/space/2x/astronaut.png" alt="Astronaut attached to spaceship" />
                    </div>
                  </div>


                  <div className="final-panel-wrapper">
                    <div className="container">
                      <div className="row justify-end">
                        <div className="col-12 col-md-7">
                          <div className="final-panel">
                            <div className="header">READY FOR NEW HORIZONS?</div>
                            <Link className="get-in-touch mt-4 mb-4 btn" to="/get-in-touch">Get in touch</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="footer">
                    <div className="container">
                      <div className="row items-center">
                        <div className="col-12 col-lg-6 links">
                          <div><strong>Reference:</strong></div>
                          <div>
                            <div className='footnote'><a href="https://kubernetes.io/case-studies/pearson/">(1) Case Study: Pearson</a></div>
                            <div className='footnote'><a href="https://kubernetes.io/case-studies/city-of-montreal/">(2) Case Study: City of Montreal</a></div>
                            <div className='footnote'><a href="https://kubernetes.io/case-studies/vsco/">(3) Case Study: VSCO</a></div>
                            <div className='footnote'><a href="https://kubernetes.io/case-studies/thredup/">(4) Case Study: ThredUp</a></div>
                            <div className='footnote'><a href="https://kubernetes.io/case-studies/huawei/">(5) Case Study: Huawei</a></div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="amazee-logo">
                            <Link to="/">
                              <img src='/space/amazee-logo.png' alt="Amazee Logo" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="stream d-none d-xxl-block">
                    <div className="rocket-ship">
                      <img src="/space/2x/top-rocket.png" alt="Rocket Ship" />
                    </div>
                    <div className="rocket-ship-beam" />
                    <img src="/space/2x/trail.png" className="stream-img" alt="Space trail" />
                  </div>

                  <div className="satellite">
                    <div className="sat"><img src="/space/4x/sat.png" alt="Satellite" /></div>
                    <div className="beam"><img src="/space/4x/sat-beam.png" alt="Satellite" /></div>
                  </div>

                  {/* <AmazeeIoMetadata title={result.title} page_meta_description={result.page_meta_description} page_meta_thumbnail={result.page_meta_thumbnail} location={location} /> */}

                  <div className="content">
                    <div className="container">

                      {/* Header */}
                      <div className="row mb-16">
                        <div className="col-12">
                          <h1>Blast off with<br />Kubernetes + Lagoon</h1>

                          <div className="row">
                            <div className="col-12 col-md-8">
                              <div className="mt-6">
                                <p className="page-description">
                                Lagoon is the open-source web hosting platform that enables global teams to scale with ease – without 
                                requiring knowledge of Kubernetes, the open-source container orchestration system trusted by the 
                                world’s most innovative organizations.
                                </p>
                              </div>
                              <div className="mt-4">
                                <img className="logos" src="/space/2x/logos.png" alt="Lagoon & Kubernetes logos" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="panels mt-16">

                        {/* Panel 1 */}
                        <SpacePagePanel
                          slug="choose"
                          header="Choose your own software, hardware, and services"
                          imageTopUrl='/space/2x/panel-1--top.png'
                          imageTopAlt='100% Application Agnostic to flexibly run anything in any container'
                          imageBottomUrl='/space/2x/panel-1--bottom.png'
                          imageBottomAlt=''
                          k8Text="Run any combination of applications all in one cluster."
                          lagoonText="Run a fully-managed stack on your infrastructure of choice without vendor lock-in."
                          planetImageUrl="/space/2x/planet-1.png"
                          isAtTop={true}
                        />

                        {/* Panel 2 */}
                        <SpacePagePanel
                          slug="focus"
                          header="Focus on your application "
                          imageTopUrl='/space/2x/panel-2--top.png'
                          imageTopAlt="15-20% developer productivity boost"
                          imageBottomUrl='/space/2x/panel-2--bottom.png'
                          imageBottomAlt="average 90% savings on hosting platform and infrastructure setup"
                          k8Text="Developers can focus on writing code instead of configuring and maintaining infrastructure."
                          lagoonText="With Lagoon, the entire hosting stack is managed by a team of experts at amazee.io, offloading its maintenance from customers’ core engineering teams."
                          planetImageUrl="/space/2x/planet-2.png"
                        />

                        {/* Panel 3 */}
                        <SpacePagePanel
                          slug="launch"
                          header="Launch and iterate faster"
                          imageTopUrl='/space/2x/panel-3--top.gif'
                          imageTopAlt="launch 4x faster"
                          imageBottomUrl='/space/2x/panel-3--bottom.png'
                          imageBottomAlt="spin up new environments in minutes"
                          k8Text="Get to market faster by streamlining your DevOps."
                          lagoonText="The Lagoon build and deploy system streamlines test environment creation and deployments."
                          planetImageUrl="/space/2x/planet-3.png"
                        />

                        {/* Panel 4 */}
                        <SpacePagePanel
                          slug="run"
                          header="Run with confidence 24x7x365"
                          imageTopUrl='/space/2x/panel-4--top.png'
                          imageTopAlt="88% downtime reduction"
                          imageBottomUrl='/space/2x/panel-4--bottom.gif'
                          imageBottomAlt="support available round the clock in 15 minutes"
                          k8Text="Achieve maximum application uptimes."
                          lagoonText="amazee.io monitors 24/7 and offers enterprise SLAs."
                          planetImageUrl="/space/2x/planet-4.png"
                        />

                        {/* Panel 5 */}
                        <SpacePagePanel
                          slug="save"
                          header="Save on major capital and operating expenses"
                          imageTopUrl='/space/2x/panel-5--top.png'
                          imageTopAlt="56% infrastructure cost savings"
                          imageBottomUrl='/space/2x/panel-5--bottom.png'
                          imageBottomAlt="up to 80% operational cost savings"
                          k8Text="Optimize hardware resources with autoscaling and dynamic re-allocation across projects as needed."
                          lagoonText="Benefit from less overhead with amazee.io providing 24x7 systems maintenance and support."
                          planetImageUrl="/space/2x/planet-5.png"
                        />

                        {/* Panel 6 */}
                        <SpacePagePanel
                          slug="grow"
                          header="Grow with ease sustainably "
                          imageTopUrl='/space/2x/panel-6--top.png'
                          imageTopAlt="96% fewer requiredin VM resource"
                          imageBottomUrl='/space/2x/panel-6--bottom.gif'
                          imageBottomAlt="adapts in seconds"
                          k8Text="Kubernetes autoscales to handle traffic spikes, eliminating the need to run (and pay for) maximum resources year-round. "
                          lagoonText="When the system is stressed, Lagoon dynamically reallocates resources across the Kubernetes cluster to prevent downtimes."
                          planetImageUrl="/space/2x/planet-6.png"
                          isAtBottom={true}
                        ><div className="meteors d-none d-xl-block" /></SpacePagePanel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </ProgressiveImage>
      </ParallaxProvider>
    </AmazeePage>
  );
}

export default SpacePage;
